import React from 'react'
import {v4} from 'uuid'
import CountUp from 'react-countup'
import VisibilitySensor from 'react-visibility-sensor'

const MetricsCounter = ({ counterItems }) => {
  const mappedCounterItems = counterItems.map((item) => (
    <div className="column is-3 counter-item" key={ v4() }>
      <CountUp end={item.count} redraw={true}>
        {({ countUpRef, start }) => (
          <VisibilitySensor onChange={start} delayedCall>
            <>
              <div className="counter-title" ref={countUpRef} />
              <hr className="hr-black" />
              <h2>{ item.title }</h2>
            </>
          </VisibilitySensor>
        )}
      </CountUp>
    </div>
  ));

  return ( 
    <div className="counter-container full-width">
      <div className="container content">
        <div className="columns">
          { mappedCounterItems }
        </div>
      </div>
    </div>
  );
};

export default MetricsCounter;