import React from 'react'
import Slider from 'react-slick';
import {v4} from 'uuid'
import VisibilitySensor from "react-visibility-sensor";
import Image from './Image'
import BackgroundTriangleAndTitle from '../components/BackgroundTriangleAndTitle'
import { HTMLContent } from '../components/Content'
import { animateStyles } from '../utils'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import LinkComponent from './LinkComponent';

const SliderAndText = ({ sliderObject, sliderImages }) => {

  const config = {
    dots: true,
    autoplay: true,
    autoplaySpeed: 4000,
    dotsClass: "slick-dots slider-text-dots",
    infinite: true,
    arrows: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  return (
    <div className="content slider-image-section">
      <BackgroundTriangleAndTitle position={ sliderObject.backgroundTriangle } title={ sliderObject.backgroundTitle } />
      <div className="columns">
        <div className="column is-1" />
        <Slider {...config} className="column is-5">
          {
            sliderImages.map((image) => (
              <Image key={ v4() } imageInfo={{ image: image.image }} className="slider-text-image" />
            ))
          }
        </Slider>
        <div className="column is-5 vertically-centered is-flex">
          <VisibilitySensor partialVisibility>
            {({ isVisible }) => (
              <div className="slider-text-content" style={isVisible ? animateStyles['fadeIn'].finish : animateStyles['fadeIn'].start}>
              <h1>{ sliderObject.title }</h1>
              <hr className="hr-black" />
              <HTMLContent content={ sliderObject.text} />
              <LinkComponent linkObject={ sliderObject.button.linkObject }>
                <div className="btn btn-main slider-text-btn">{ sliderObject.button.buttonText }</div>
              </LinkComponent>
            </div>
          )}
          </VisibilitySensor>
        </div>
      </div>
      <div className="column is-1" />
    </div>
  );
};

export default SliderAndText;
