import React, { Component } from 'react'
import VisibilitySensor from "react-visibility-sensor"
import BackgroundImage from './BackgroundImage'
import { ImageOverlay } from '../utils'
import LinkComponent from './LinkComponent'
import { HTMLContent } from './Content'
import { sliderStyles, animateStyles } from '../utils'


class HeroSlide extends Component {
    render() {
      const { ...props } = this.props;
      const { image, active, index } = props;
      const activeClass = index === active ? 'active-hero' : '';
      return (
        <BackgroundImage classes={`hero-slide-image vertically-centered ${activeClass}`} imageInfo={image.image}>
            <VisibilitySensor>
            {({ isVisible }) => (
                <div
                style={sliderStyles[image.position]}
                className="container content hero-slide-content"
                >
                <div style={isVisible ? animateStyles[image.animation].finish : animateStyles[image.animation].start }>
                {!!image.heroTitle ?
                    <>
                    <HTMLContent className="hero-slide-markdown" content={image.heroTitle} />
                    <div className="horizontal-rule-white slider-rule" />
                    <br/>
                    </>
                    : null
                }
                {!!image.heroSubtitle ?
                    <HTMLContent className="hero-slide-markdown" content={image.heroSubtitle} />
                    : null
                }
                {!!image.buttonObject.buttonText ?
                    <LinkComponent linkObject={image.buttonObject.linkObject}>
                    <div className="btn btn-transparent">{image.buttonObject.buttonText}</div>
                    </LinkComponent>
                    : null
                } 
                </div>  
                </div>    
            )}
            </VisibilitySensor>
            <ImageOverlay overlay={image.overlay} />
        </BackgroundImage>
      );
    }
}
export default HeroSlide