import React, { Component } from 'react'
import Slider from 'react-slick'
import {v4} from 'uuid'
import HeroSlide from './HeroSlide'

class HeroSlider extends Component {
  constructor() {
    super();
    this.state = {
        activeSlide: 0
    };
}
  render () {
    const config = {
      dots: true,
      arrows: false,
      dotsClass: "slick-dots hero-slick-dots",
      infinite: true,
      autoplay: true,
      autoplaySpeed: 4000,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,    
      beforeChange: (current, next) => this.setState({ activeSlide: next }),
      useCSS: true
    };
    return (
      <Slider {...config} className="full-width hero-slider" ref={slider => (this.slider = slider)}>
          { this.props.heroImages.map((image, index) => <HeroSlide key={v4()} image={ image } index={ index } active={ this.state.activeSlide } />) }
      </Slider>
    );
  }
};

export default HeroSlider
