import React from 'react';
import ImageBlock from './ImageBlock'

const IndexPageComponents = ({ pageComponents }) => (
  <>
  <div className="container content index-page-header">
      <h2>OUR LIFESTYLE REAL ESTATE OFFER</h2>
    <div className="horizontal-rule-black"></div>
    <div className="triangle-left-top full-width" />
  </div>
  <div className="full-width">
    {pageComponents.map((component, index) => {
      return (      
        <div className="index-page-content" key={ `IndexPageComponent--${index}` }>
          <div className="columns">
            <ImageBlock imageBlock={ component } />
          </div>
        </div>
      );
    })}
  </div>
  </>
);

export default IndexPageComponents