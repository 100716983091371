import React from 'react';
import { Parallax } from 'react-parallax';

const FullWidthParallax = ({ videoBackgroundImage }) => {
  return (
    <div className="full-width">
        <Parallax
            bgClassName="cover"
            bgImage={videoBackgroundImage}
            bgImageAlt="Mt Yotei"
            strength={-100}
        >
            <div className="three-quarter-height vertically-centered"></div>
        </Parallax>
        </div>
  );
}

export default FullWidthParallax
